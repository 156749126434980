import React, { useState, useEffect, useRef } from 'react';
import "../css/CaseList.css";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import hamburgerIcon from "../icons/hamburger-icon.svg";
import rightIcon from "../icons/right-icon.svg";

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function BillsList({ toggleMobileSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();

    const [bills, setBills] = useState(location.state?.bills || []); // Prepopulate if passed via state
    const [searchQuery, setSearchQuery] = useState('');
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false); // Loading state for infinite scroll
    const [isModalLoading, setIsModalLoading] = useState(false); // Loading state for PDF processing
    const observer = useRef();

    const folderName = location.state?.folderName || 'Bills';

    useEffect(() => {
        if (bills.length === 0) {
            fetchBills(); // Fetch initial list of bills
        }
    }, []);

    useEffect(() => {
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                fetchBills();
            }
        });

        const sentinel = document.querySelector('#sentinel');
        if (sentinel) observer.current.observe(sentinel);

        return () => {
            if (sentinel) observer.current.unobserve(sentinel);
        };
    }, [hasMore, loading]);

    const fetchBills = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/bills`, {
                params: {
                    folder: folderName,
                    cursor: cursor,
                    limit: 10,
                },
            });

            if (response.data && Array.isArray(response.data.bills)) {
                if (response.data.bills.length > 0) {
                    setBills((prevBills) => [...prevBills, ...response.data.bills]);
                    setCursor(response.data.cursor);
                    setHasMore(response.data.cursor !== null);
                } else {
                    setHasMore(false);
                }
            } else {
                console.error('Unexpected response structure:', response.data);
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching bills:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBillClick = async (url, billName) => {
        setIsModalLoading(true);
        try {
            const pdfResponse = await axios.get(url, { responseType: 'blob' });
            if (!pdfResponse || !pdfResponse.data) {
                console.error('Failed to download the PDF from the provided URL');
                return;
            }

            const formData = new FormData();
            formData.append('file', new File([pdfResponse.data], `${billName}.pdf`, { type: 'application/pdf' }));

            const uploadResponse = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/upload_act_pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status === 200) {
                const { act_document_id } = uploadResponse.data;
                localStorage.setItem('act_document_id', act_document_id);
                console.log('File uploaded and processing triggered:', uploadResponse.data.message);

                // Navigate to AddBills page
                navigate('/addbills', {
                    state: { actName: billName, pdfUrl: url, documentId: act_document_id }
                });
            } else {
                console.error('Error uploading the file:', uploadResponse.data.detail);
            }
        } catch (error) {
            console.error('Error processing PDF:', error);
        } finally {
            setIsModalLoading(false);
        }
    };

    const removePdfExtension = (title) => {
        return title.replace(/\.pdf$/i, '');
    };

    const filteredBills = bills.filter(bill =>
        removePdfExtension(bill.billName).toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="caselist">
            <LoadingModal isLoading={isModalLoading} /> {/* Show loading modal when a bill is clicked */}

            <div className="caselist-header">
                {/* <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button> */}
                <button
                    onClick={() => navigate(-1)}
                    className="addcase-back-button"
                >
                    <img src={rightIcon} alt="" className="back-icon" />
                </button>
                <h3>{folderName}</h3>
            </div>

            <div className="search-bar">
                <div className="search-icon">
                    <FaSearch />
                </div>
                <input
                    type="text"
                    placeholder="Search Bills"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="section-divider"></div>
            <div className="caselist-content" style={{ marginTop: "50px" }}>
                <div className="caselist-thread-section">
                    {filteredBills.map((bill, index) => (
                        <div
                            key={index}
                            className="caselist-thread-item"
                            onClick={() => handleBillClick(bill.url, removePdfExtension(bill.billName))}
                        >
                            <div className="caselist-thread-title">{removePdfExtension(bill.billName)}</div>
                            <div className="caselist-thread-body">Click to view the Original Bill</div>
                            <div className="caselist-thread-meta">
                                <span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0-8 4-15.5 10.7-20l96-64c11-7.4 25.9-4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                    </svg>
                                    {bill.date}
                                </span>
                            </div>
                        </div>
                    ))}

                    {filteredBills.length === 0 && !loading && (
                        <div className="no-results">
                            No results found
                        </div>
                    )}

                    {loading && <div>Loading...</div>}
                    <div id="sentinel" style={{ height: '1px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default BillsList;
