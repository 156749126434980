import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/StateSelection.css';
import searchIcon from "../icons/search-icon.svg";
import backIcon from "../icons/Back-button-icon.svg";
import axios from 'axios';

const StateSelection = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const folders = [
        { name: "Andaman and Nicobar", value: "Andaman and Nicobar Islands" },
        { name: "Andhra Pradesh", value: "Andhra Pradesh" },
        { name: "Arunachal Pradesh", value: "Arunachal Pradesh" },
        { name: "Assam", value: "Assam" },
        { name: "Bihar", value: "Bihar" },
        { name: "Chattisgarh", value: "Chattisgarh" },
        { name: "Dadra And Nagar Haveli And Diu", value: "Dadra And Nagar Haveli And Diu" },
        { name: "Delhi", value: "Delhi" },
        { name: "Goa", value: "Goa" },
        { name: "Gujarat", value: "Gujarat" },
        { name: "Haryana", value: "Haryana" },
        { name: "Himachal Pradesh", value: "Himachal Pradesh" },
        { name: "Jammu and Kashmir", value: "Jammu and Kashmir" },
        { name: "Jharkhand", value: "Jharkhand" },
        { name: "Karnataka", value: "Karnataka" },
        { name: "Kerala", value: "Kerala" },
        { name: "Ladakh", value: "Ladakh" },
        { name: "Lakshadweep", value: "Lakshadweep" },
        { name: "Madhya Pradesh", value: "Madhya Pradesh" },
        { name: "Maharashtra", value: "Maharashtra" },
        { name: "Manipur", value: "Manipur" },
        { name: "Meghalaya", value: "Meghalaya" },
        { name: "Mizoram", value: "Mizoram" },
        { name: "Nagaland", value: "Nagaland" },
        { name: "Odisha", value: "Odisha" },
        { name: "Pondicherry", value: "Pondicherry" },
        { name: "Punjab", value: "Punjab" },
        { name: "Rajasthan", value: "Rajasthan" },
        { name: "Sikkim", value: "Sikkim" },
        { name: "Tamil Nadu", value: "Tamil Nadu" },
        { name: "Telangana", value: "Telangana" },
        { name: "Tripura", value: "Tripura" },
        { name: "Uttar Pradesh", value: "Uttar Pradesh" },
        { name: "Uttarakhand", value: "Uttarakhand" },
        { name: "West Bengal", value: "West Bengal" }
    ];

    const handleFolderSelect = async (folder) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/fetchActs?folder=${folder}`);
            const data = response.data;
            navigate('/actslist', { state: { acts: data.acts, folderName: folder } });
        } catch (error) {
            console.error("Error fetching acts:", error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    return (
        <div className="state-selection-page">

            <button className="stateselection-back-button" onClick={() => navigate(-1)}>
                <span><img src={backIcon} alt="close icon" /></span>
            </button>

            <h2 className='state-selection-header'>Select a State</h2>

            <div className="mycase-search-bar">
                <div className="mycase-search-wrapper">
                    <img className="mycase-search-icon" src={searchIcon} alt="" />
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            <div className="state-list">
                {folders.map((folder) => {
                    const isMatch = searchQuery && folder.name.toLowerCase().includes(searchQuery);
                    return (
                        <div
                            key={folder.value}
                            className={`state-item ${isMatch ? 'highlight' : ''}`}
                            onClick={() => handleFolderSelect(folder.value)}
                        >
                            {folder.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default StateSelection;
