import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/CaseList.css'; // Reuse the existing CSS

function YearList() {
    const navigate = useNavigate();
    const years = Array.from({ length: 2024 - 1950 + 1 }, (_, index) => 1950 + index);

    const handleYearClick = (year) => {
        navigate(`/caselist?year=${year}`);
    };

    return (
        <div className="caselist-thread-section">
            <div className="caselist-thread-header">
                <h2>Select Year</h2>
            </div>
            <div className="caselist-thread-body">
                {years.map((year) => (
                    <div
                        key={year}
                        className="caselist-thread-item"
                        onClick={() => handleYearClick(year)}
                    >
                        <div className="caselist-thread-title">{year}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default YearList;
