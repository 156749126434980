import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../css/LandmarkList.css";
import "../css/CaseList.css"
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import hamburgerIcon from "../icons/hamburger-icon.svg";
import backIcon from "../icons/Back-button-icon.svg";
import searchIcon from "../icons/search-icon.svg";

const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function LandmarkList({ toggleMobileSidebar }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [category, setCategory] = useState('All');
    const [caseType, setCaseType] = useState('All');
    const [year, setYear] = useState('All');
    const [landmarks, setLandmarks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    // Determines if any filters are applied
    const filtersApplied = caseType !== 'All' || year !== 'All';

    const fetchLandmarks = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/landmarkJudgements`, {
                params: {
                    category: category === 'All' ? null : category,
                    caseType: caseType === 'All' ? null : caseType,
                    year: year === 'All' ? null : year
                }
            });
            setLandmarks(response.data.cases || []); // Set landmarks data directly from response
        } catch (err) {
            setError("Failed to load landmark judgments.");
            console.error("Error fetching data:", err);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch landmarks when filters change
    useEffect(() => {
        fetchLandmarks(); // Fetch data based on the new filters
    }, [category, caseType, year]);

    // Filter function for search query
    const filteredLandmarks = landmarks.filter((landmark) =>
        landmark.caseName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const uploadAndProcessPdf = async (url, caseName, additionalCaseData) => {
        try {
            const pdfResponse = await axios.get(url, { responseType: 'blob' });
            if (!pdfResponse || !pdfResponse.data) {
                console.error('Failed to download the PDF from the provided URL');
                return;
            }

            const formData = new FormData();
            formData.append('file', new File([pdfResponse.data], `${caseName}.pdf`, { type: 'application/pdf' }));

            const uploadResponse = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/upload_case_pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status === 200) {
                const document_id = uploadResponse.data.case_document_id;
                localStorage.setItem('case_document_id', document_id);
                navigate('/addcase', {
                    state: {
                        pdfUrl: url,
                        caseName,
                        document_id,
                        ...additionalCaseData
                    }
                });
            } else {
                console.error('Error uploading the file:', uploadResponse.data.detail);
            }
        } catch (error) {
            console.error('Error processing PDF:', error);
        }
    };

    return (
        <div className="landmark-list">
            <LoadingModal isLoading={isLoading} />
            <div className="landmark-list-header">
                {/* <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button> */}
                <h3>Landmark Judgments</h3>
                <button className="landmark-back-button" onClick={() => navigate(-1)}>
                    <span><img src={backIcon} alt="close icon" /></span>
                </button>
            </div>
            <div className='section-divider'></div>
            <div className='landmark-header'>
                <div className="landmark-search-bar">
                    <div >
                        <img className="landmark-search-icon" src={searchIcon} alt="" />
                    </div>
                    <input
                        type="text"
                        placeholder="Search Landmark Judgment"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="filters">
                    <div className="filter-group">
                        <label htmlFor="caseType">Case Type: </label>
                        <select
                            id="caseType"
                            value={caseType}
                            onChange={(e) => setCaseType(e.target.value)}
                        >
                            <option value="" disabled>
                                Select Case Type
                            </option>
                            <option value="All">All</option>
                            <option value="Arbitration Petition">Arbitration Petition</option>
                            <option value="Civil Appeal">Civil Appeal</option>
                            <option value="Criminal Appeal">Criminal Appeal</option>
                            <option value="Curative Petition (Civil)">Curative Petition (Civil)</option>
                            <option value="Miscellaneous Application 1699/2019 in W.P.(C)">
                                Miscellaneous Application 1699/2019 in W.P.(C)
                            </option>
                            <option value="Miscellaneous Application 2204/2020 in W.P.(Crl.)">
                                Miscellaneous Application 2204/2020 in W.P.(Crl.)
                            </option>
                            <option value="Review Petition (Criminal)">
                                Review Petition (Criminal)
                            </option>
                            <option value="Special Leave Petition (Civil)">
                                Special Leave Petition (Civil)
                            </option>
                            <option value="Special Leave Petition (Criminal)">
                                Special Leave Petition (Criminal)
                            </option>
                            <option value="Suo Motu Case (Civil)">Suo Motu Case (Civil)</option>
                            <option value="Suo Motu Writ (Civil)">Suo Motu Writ (Civil)</option>
                            <option value="Suo Motu Writ (Criminal)">Suo Motu Writ (Criminal)</option>
                            <option value="Transfer Petition (Civil)">
                                Transfer Petition (Civil)
                            </option>
                            <option value="Writ Petition (Civil)">Writ Petition (Civil)</option>
                            <option value="Writ Petition (Criminal)">Writ Petition (Criminal)</option>
                        </select>
                    </div>
                    <div className="filter-group">
                        <label htmlFor="year">Years: </label>
                        <select id="year" value={year} onChange={(e) => setYear(e.target.value)}>
                            <option value="" disabled>
                                Select Year
                            </option>
                            <option value="All">All</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                        </select>
                    </div>
                </div>

            </div>

            <div className="landmark-list-content">
                {isLoading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div className="error-message">{error}</div>
                ) : (
                    <>
                        <div className="landmark-thread-section">
                            {filteredLandmarks.map((landmark, index) => (
                                <div
                                    key={index}
                                    className="landmark-thread-item"
                                    onClick={() => uploadAndProcessPdf(landmark.url, landmark.caseName, {
                                        caseType: landmark.caseType,
                                        date: landmark.date,
                                        judges: landmark.judges,
                                        citation: landmark.citation
                                    })}
                                >
                                    <div className="landmark-thread-title">{landmark.caseName}</div>
                                    <div className="landmark-thread-body">{`Case Type: ${landmark.caseType}`}</div>
                                    <div className="landmark-thread-meta">
                                        <span className='date-meta'>{landmark.date}</span>
                                    </div>
                                </div>
                            ))}
                            {filteredLandmarks.length === 0 && (
                                <div className="no-results">No results found</div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default LandmarkList;
