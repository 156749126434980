import React, { useState, useRef, useEffect } from "react";
import "../css/CaseFinder.css";
import { FaSearch } from "react-icons/fa";
import { Button, Modal, Box, Typography, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import caseFinderHeaderIcon from "../icons/casefinder-header-icon.svg";
import clockIcon from "../icons/clock-icon.svg";
import archiveIcon from "../icons/archive-chat-icon.svg";
import threeDots from "../icons/three-dots-icon.svg";
import closeButton from "../icons/close-button-icon.svg";
import hamburgerIcon from "../icons/hamburger-icon.svg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import axios from "axios";
import searchIcon from "../icons/search-icon.svg";

const LoadingModal = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <div className="spinner"></div>
        <p>Loading, please wait...</p>
      </div>
    </div>
  );
};

function CaseFinder({ toggleMobileSidebar }) {
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false); // Added state for upload modal
  const [openModalsearch, setopenModalsearch] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [arrowSearchColor, setArrowSearchColor] = useState("#00000"); // Default color for arrow-search
  const [arrowSearchBackgroundColor, setArrowSearchBackgroundColor] =
    useState("#f1efef"); // Default color for arrow-search background
  const [arrowSearchPointer, setArrowSearchPointer] = useState("pointer"); // Default color for arrow-search background
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const textareaRef = useRef(null);
  const [emoji, setEmoji] = useState(null); // To store selected emoji
  const [showPicker, setShowPicker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Shareable");
  const optionsContainerRef = useRef(null);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const chatDisplayRef = useRef(null);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isChatSpinner, setIsChatSpinner] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [isCaseFinderCollectionsVisible, setIsCaseFinderCollectionsVisible] = useState(false);

  const [chatId, setChatId] = useState(
    localStorage.getItem("caseFinder_chat_id") || ""
  ); // Retrieve chat_id from localStorage if it exists

  const [caseName, setCaseName] = useState(""); // Added caseName state
  const [file, setFile] = useState(null); // Added file state

  const navigate = useNavigate();

  const handleOpenLandmark = () => {
    // Navigate to LandmarkList when "Landmark Judgements" is clicked
    navigate("/landmarklist");
  };

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [chatMessages, isChatLoading]);

  useEffect(() => {
    // Load chat messages from localStorage on mount
    const storedChatMessages =
      JSON.parse(localStorage.getItem("caseFinder_chatMessages")) || [];
    setChatMessages(storedChatMessages);
  }, []);


  // const handleUploadClick = () => {
  //     setOpenUploadModal(true);
  // };

  // const handleCloseUploadModal = () => {
  //     setOpenUploadModal(false);
  // };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file
  };

  const handleCaseNameChange = (event) => {
    setCaseName(event.target.value); // Set the case name input
  };

  const handleSubmit = () => {
    // Handle the submit logic, e.g., uploading the file and case name
    console.log("Case Name:", caseName);
    console.log("File:", file);
    handleCloseUploadModal();
  };

  const years = [
    { year: 2022, count: 99 },
    // Add more years here...
  ];

  const handleMobileDropdownToggle = () => {
    setMobileDropdownOpen((prev) => !prev);
  };

  const handleCloseSearchModal = () => {
    setopenModalsearch(false);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    updateArrowSearchColor(value);
    adjustTextareaHeight();
  };

  const handleArrowSearchClick = () => {
    console.log("Arrow search clicked");
  };

  const updateArrowSearchColor = (value) => {
    if (value.trim() !== "") {
      setArrowSearchColor("#00000");
      setArrowSearchBackgroundColor("#21b8cd");
      setArrowSearchPointer("pointer");
    } else {
      setArrowSearchColor("#00000");
      setArrowSearchBackgroundColor("#f1efef");
      setArrowSearchPointer("");
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleSendClick = async () => {
    if (!inputValue.trim()) return;

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      console.error("Error: Token or user_id is missing.");
      return;
    }

    const newMessage = { sender: "user", content: inputValue };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputValue("");

    try {
      setIsChatLoading(true);

      // Log the full URL for debugging
      const url = `${process.env.REACT_APP_PROD_AI_URL}/chat/generate_query`;
      console.log("Requesting AI query generation at:", url);

      const response = await axios.post(
        url,
        { user_query: newMessage.content },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Extract and format results
      const { results = [] } = response.data; // Default to an empty array if results is undefined
      console.log("Received response data:", response.data);

      let formattedResults;

      if (results.length > 0) {
        // Convert results to a Markdown string for ReactMarkdown
        formattedResults = results
          .map((res) => `- [${res.case_title}](${res.gcs_path})`)
          .join("\n");
      } else {
        // No results found
        formattedResults =
          "No judgments found in the database regarding your query.";
      }

      const aiResponse = { sender: "assistant", content: formattedResults };
      setChatMessages((prevMessages) => [...prevMessages, aiResponse]);
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response?.data || error.message
      );
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "assistant",
          content: "No judgments found in the database regarding your query.",
        },
      ]);
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleLinkClick = (url, caseName) => {
    setIsChatSpinner(true); // Trigger loading modal
    uploadAndProcessPdf(url, caseName);
  };

  const uploadAndProcessPdf = async (url, caseName) => {
    try {
      console.log("Downloading PDF from:", url);
      const pdfResponse = await axios.get(url, { responseType: "blob" });
      if (!pdfResponse || !pdfResponse.data) {
        console.error("Failed to download the PDF from the provided URL");
        setIsChatSpinner(false); // Hide loading modal on error
        return;
      }

      const formData = new FormData();
      formData.append(
        "file",
        new File([pdfResponse.data], `${caseName}.pdf`, {
          type: "application/pdf",
        })
      );

      const uploadUrl = `${process.env.REACT_APP_PROD_AI_URL}/chat/upload_case_pdf`;
      console.log("Uploading PDF to:", uploadUrl);

      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (uploadResponse.status === 200) {
        const document_id = uploadResponse.data.case_document_id;
        localStorage.setItem("case_document_id", document_id);
        navigate("/addcase", {
          state: { pdfUrl: url, caseName, document_id },
        });
      } else {
        console.error("Error uploading the file:", uploadResponse.data.detail);
      }
    } catch (error) {
      console.error("Error processing PDF:", error);
    } finally {
      setIsChatSpinner(false); // Always hide loading modal after processing
    }
  };


  const handleOpenModalSearch = () => {
    setopenModalsearch(true);
    setOpenModal(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setopenModalsearch(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setopenModalsearch(false);
  };

  const handleUploadClick = () => {
    setOpenUploadModal(true); // Open the upload modal
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false); // Close the upload modal
  };

  const handleEmojiClick = (event, emojiObject) => {
    setEmoji(emojiObject.srcElement.innerText);
    console.log(`Selected Emoji: ${emojiObject.emoji}`);
    setShowPicker(false);
  };

  const handleDeleteEmoji = (event) => {
    event.stopPropagation();
    setEmoji(null);
  };

  const options = [
    { value: "secret", label: "Secret" },
    { value: "shareable", label: "Shareable" },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      optionsContainerRef.current &&
      !optionsContainerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const goToHighCourt = () => {
    navigate("/yearlist");
  };

  const goToSupremeYearList = () => {
    navigate("/caseyearlist");
  };

  const [isFullScreen, setIsFullScreen] = useState(false);


  

  const handleResize = () => {
    // Dynamically adjust fullscreen mode based on screen width
    if (window.innerWidth <= 768) {
      setIsFullScreen(true);
      console.log("Resized to mobile view: Fullscreen enabled");
    } else {
      setIsFullScreen(false);
      console.log("Resized to desktop view: Normal display");
    }
  };
  
  // Ensure the correct fullscreen mode is set when the box is first opened
  const handleSearchJudgmentClick = () => {
    setIsCaseFinderCollectionsVisible(true); // Show the box
    handleResize(); // Check and set fullscreen mode immediately
  };
  
  useEffect(() => {
    // Add event listener for resize
    window.addEventListener("resize", handleResize);
  
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Dependency array is empty because we don't need to track any state
  



  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent new line in the input
      handleSendClick();
    }
  };

  return (
    <div className="library">
      <div className="library-header">
        <button className="recentchat-mobile-hamburger" onClick={toggleMobileSidebar} >
          <img src={hamburgerIcon} alt="Hamburger Icon" />
        </button>

        <div>
          <h3>Case Judgments</h3>
        </div>

        <div className="mobile-options">
          <span
            className="three-dots-icon"
            onClick={handleMobileDropdownToggle}
          >
            <img
              src={threeDots}
              alt="three dots icon"
              className="view-caselist-icon"
            />
          </span>
          {mobileDropdownOpen && (
            <div className="mobile-dropdown">
              <div className="dropdown-mobileoption">Upload your Judgement</div>
              <div className="dropdown-mobileoption" onClick={handleSearchJudgmentClick} >Search case Judgement</div>
            </div>
          )}
        </div>
      </div>
      <div className="section-divider"></div>
      {/* <div className="casefinder-search-bar">
        <div className="casefinder-search-wrapper">
          <img className="casefinder-search-icon" src={searchIcon} alt="" />
          <input type="text" placeholder="Search Court Judgment" />
        </div>
      </div> */}
      <div id="divider"></div>

      <div className="library-content" >
        <div className="threads-section">
          {/* Threads Section */}
          <div className="thread-item" onClick={handleOpenLandmark}>
            <div className="thread-title">Landmark Judgments</div>
            <div className="thread-body">87 Judgments</div>
            {/* <div className="thread-meta">
                            <span>2 days ago</span>
                        </div> */}
          </div>
          <div className="thread-item" onClick={goToSupremeYearList}>
            <div className="thread-title">Supreme Court</div>
            <div className="thread-body">2808 Judgments</div>
            {/* <div className="thread-meta">
                            <span>Today</span>
                        </div> */}
          </div>
          <div className="thread-item">
            <div className="thread-title" onClick={goToHighCourt}>
              High Courts
            </div>
            <div className="thread-body">Coming Soon</div>
            {/* <div className="thread-meta">
                            <span>10 days ago</span>
                        </div>
                    </div>
                    <div className="thread-item" >
                        <div className="thread-title">Session Courts (DCDRF, SCDRC, NCDRC)</div>
                        <div className="thread-body">Coming Soon</div>
                        {/* <div className="thread-meta">
                            <span>1 day ago</span>
                        </div> */}
          </div>
          <div className="thread-item">
            <div className="thread-title">District Courts</div>
            <div className="thread-body">Coming Soon</div>
            {/* <div className="thread-meta">
                            <span>9 days ago</span>
                        </div> */}
          </div>
          <div className="thread-item">
            <div className="thread-title">Special Courts</div>
            <div className="thread-body">Coming Soon</div>
            {/* <div className="thread-meta">
                            <span>1 day ago</span>
                        </div> */}
          </div>
          <div className="thread-item">
            <div className="thread-title">Gram Panchayat</div>
            <div className="thread-body">Coming Soon</div>
            {/* <div className="thread-meta">
                            <span>1 day ago</span>
                        </div> */}
          </div>
        </div>


        {isCaseFinderCollectionsVisible && (
          <div
            className={`caseFinder-collections-section ${isFullScreen ? "fullscreen" : ""
              }`}
          >
            <h3>Search Case Judgments</h3>
            <div className="caseFinder-chat-display" ref={chatDisplayRef}>
              {chatMessages.map((msg, index) => (
                <div key={index} className={`caseFinder-chat-message ${msg.sender}`}>
                  <div className={`caseFinder-message-bubble ${msg.sender}`}>
                    {msg.sender === "assistant" ? (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: ({ href, children }) => (
                            <span
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={() => handleLinkClick(href, children)}
                            >
                              {children}
                            </span>
                          ),
                        }}
                      >
                        {msg.content}
                      </ReactMarkdown>
                    ) : (
                      <span>{msg.content}</span>
                    )}
                  </div>
                </div>
              ))}
              {isChatLoading && (
                <div className="loading-indicator">Loading Response...</div>
              )}
            </div>
            <div className="caseFinder-chat-prompt">
              <div className="caseFinder-chatbox-input-wrapper">
                <input
                  type="text"
                  className="caseFinder-chatbox-input"
                  placeholder="Type something"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="caseFinder-chatbox-buttons">
                  <button
                    className="caseFinder-chatbox-send"
                    onClick={handleSendClick}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}



       



      </div>

      {/* Upload Modal */}
      <Modal
        open={openUploadModal}
        onClose={handleCloseUploadModal}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
      >
        <div className="modal-box-upload">
          <Typography
            id="upload-modal-title"
            variant="h6"
            component="h2"
            className="modal-title"
          >
            Upload Case
          </Typography>
          <Typography variant="body1" component="p" className="modal-label">
            Case Name:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter case name"
            value={caseName}
            onChange={handleCaseNameChange}
            className="modal-input"
          />
          <br></br>
          <br></br>
          <Typography variant="body1" component="p" className="modal-label">
            Attach Document:
          </Typography>
          <input
            type="file"
            onChange={handleFileChange}
            className="modal-file-input"
          />
          <div className="modal-button-group">
            <Button variant="contained" color="dark" onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="dark"
              onClick={handleCloseUploadModal}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="modal-box-year">
          <span
            onClick={handleCloseModal}
            id="close-button"
            className="close-button"
          >
            <img
              src={closeButton}
              alt="close button icon"
              className="close-caselist-icon"
            />
          </span>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            className="modal-title"
          >
            Year
          </Typography>
          <div id="divider"></div>
          <div className="year-list">
            {years.map((item) => (
              <div
                key={item.year}
                className="year-item"
                onClick={() =>
                  navigate("/caselist", { state: { selectedYear: item.year } })
                }
                style={{ cursor: "pointer" }}
              >
                <div className="year-item-header">
                  <span className="year">{item.year}</span>
                  <span className="count">{item.count}</span>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CaseFinder;
