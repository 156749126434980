import React, { useState, useEffect, useRef, useContext } from "react";
import "../css/MainContent.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import RightPanel from "./RightPanel";
import Sidebar from "./Sidebar";
import editIcon from "../icons/edit-icon.svg";
import threeDots from "../icons/three-dots-icon.svg";
import attachIcon from "../icons/attach-icon.svg";
import refreshIcon from "../icons/refresh-icon.svg";
import categoryIcon from "../icons/category-icon.svg";
import documentIcon from "../icons/document-icon.svg";
import timerIcon from "../icons/timer-icon.svg";
import clockIcon from "../icons/clock-icon.svg";
import aiAvatar from "../icons/karmalogo.png";
import closeIcon from "../icons/close-button-icon.svg";
import gavelIcon from "../icons/gavel-icon.svg";
import docsIcon from "../icons/docs-icon.svg";
import hamburgerIcon from "../icons/hamburger-icon.svg";
import sendchatIcon from "../icons/send-chat-icon.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import infiniteLoader from "../icons/4 Fading blocks.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatContext } from "./ChatContext";

const MainSection = ({ toggleMobileSidebar }) => {
  const [inputValue, setInputValue] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [mode, setMode] = useState("Legal Language");
  const [subheaderDropdownOpen, setSubheaderDropdownOpen] = useState(false);
  const [attachDropdownOpen, setAttachDropdownOpen] = useState(false);
  const [viewSourceDropdownOpen, setViewSourceDropdownOpen] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [sourceDocuments, setSourceDocuments] = useState([]);
  const [lastMessageTime, setLastMessageTime] = useState(null);
  const [chatName, setChatName] = useState("Chat Name");
  const [isEditingChatName, setIsEditingChatName] = useState(false);
  const [editedChatName, setEditedChatName] = useState(chatName);
  const [isChatNameEdited, setIsChatNameEdited] = useState(false);
  const [uploadedFileText, setUploadedFileText] = useState("");
  const [documentId, setDocumentId] = useState(null);

  const textareaRef = useRef(null);
  const chatDisplayRef = useRef(null);
  const hiddenChatRef = useRef(null);
  const fileInputRef = useRef(null);
  const dropdownRefs = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { chatData, askKarmaReset } = useContext(ChatContext);
  const [chatMessages, setChatMessages] = useState([]);

  // Clear and initialize specific localStorage keys on component mount
  useEffect(() => {
    // Clear only keys with the 'mainSection_' prefix
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("mainSection_")) {
        localStorage.removeItem(key);
      }
    });

    // Set initial values for mainSection keys
    localStorage.setItem("mainSection_chat_id", "");
    localStorage.setItem("mainSection_chatMessages", JSON.stringify([]));
    localStorage.setItem("mainSection_showActionButtons", JSON.stringify(true));
    localStorage.setItem("mainSection_chatName", "Chat Name");
    localStorage.setItem("mainSection_lastMessageTime", null);
  }, []);

  useEffect(() => {
    // If chatData's chat_id is null, reset the chat view
    if (chatData?.chat_id === null) {
      resetChatMessages();
    }
  }, [chatData]);

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const resetChatMessages = () => {
    setChatMessages([]);
    setShowActionButtons(true); // Show action buttons when starting a new chat
    setChatName("Chat Name");
    setEditedChatName("Chat Name"); // Reset the edited chat name as well
    setIsChatNameEdited(false); // Reset the edited flag
    localStorage.removeItem("mainSection_chat_id"); // Clear persisted chat_id
    localStorage.removeItem("mainSection_chatMessages"); // Clear persisted chat messages
    localStorage.removeItem("mainSection_chatName"); // Clear persisted chat name
  };

  useEffect(() => {
    localStorage.setItem(
      "mainSection_chatMessages",
      JSON.stringify(chatMessages)
    );
    // localStorage.setItem('mainSection_showActionButtons', JSON.stringify(showActionButtons));
  }, [chatMessages, showActionButtons]);

  // Load chat history and name from localStorage on component mount
  useEffect(() => {
    const storedChatMessages = localStorage.getItem("mainSection_chatMessages");
    const storedChatName = localStorage.getItem("mainSection_chatName");
    const storedLastMessageTime = localStorage.getItem(
      "mainSection_lastMessageTime"
    );
    const storedActionButtons = localStorage.getItem(
      "mainSection_showActionButtons"
    );

    if (storedChatMessages) {
      setChatMessages(JSON.parse(storedChatMessages));
    }

    if (storedChatName) {
      setChatName(storedChatName);
      setEditedChatName(storedChatName);
    } else {
      setChatName("Chat Name");
      setEditedChatName("Chat Name");
    }

    if (storedLastMessageTime) {
      setLastMessageTime(storedLastMessageTime);
    } else {
      setLastMessageTime(null);
    }

    if (storedActionButtons !== null) {
      setShowActionButtons(JSON.parse(storedActionButtons));
    }
  }, []);

  // Save chat messages, name, and other state variables to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      "mainSection_chatMessages",
      JSON.stringify(chatMessages)
    );
    localStorage.setItem("mainSection_chatName", chatName);
    localStorage.setItem("mainSection_lastMessageTime", lastMessageTime);
    localStorage.setItem(
      "mainSection_showActionButtons",
      JSON.stringify(showActionButtons)
    );
  }, [chatMessages, chatName, lastMessageTime, showActionButtons]);

  useEffect(() => {
    if (chatData && chatData.chat_history) {
      setChatMessages(chatData.chat_history); // Set messages
      if (!isChatNameEdited) {
        setChatName(chatData.chat_name); // Set chat name only if not edited
        setEditedChatName(chatData.chat_name);
      }
      setShowActionButtons(false); // Hide action buttons when chat history is loaded
      setSourceDocuments(chatData.sourceDocuments || []);
    }
  }, [chatData, isChatNameEdited]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "20px"; // Set to default height initially
      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        // If the content overflows, set height to scrollHeight
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  };

  const updateChatName = async (newName) => {
    try {
      const storedChatId = localStorage.getItem("mainSection_chat_id");
      if (!storedChatId) {
        console.error("Chat ID is missing");
        return;
      }

      // Use query parameters to send `chat_id` and `new_name`
      const response = await axios.post(
        `${process.env.REACT_APP_PROD_AI_URL}/chat/update_chat_name`,
        null, // No request body needed, so we pass `null` here
        {
          params: {
            chat_id: storedChatId,
            new_name: newName,
          },
        }
      );

      console.log("Chat name updated successfully in the backend");
    } catch (error) {
      console.error("Failed to update chat name in the backend:", error);
    }
  };

  const handleChatNameChange = (e) => {
    setEditedChatName(e.target.value);
  };

  const handleEditChatName = () => {
    setIsEditingChatName(true);
  };

  const handleSaveChatName = () => {
    if (editedChatName.trim() !== "") {
      setChatName(editedChatName);
      setIsChatNameEdited(true); // Mark the chat name as edited
      localStorage.setItem("mainSection_chatName", editedChatName); // Save to localStorage immediately
      updateChatName(editedChatName); // Call API to update chat name in backend
    }
    setIsEditingChatName(false);
  };

  const formatLastMessageTime = (time) => {
    if (!time || isNaN(new Date(time).getTime())) {
      return "No conversations yet"; // Return default message for null or invalid date
    }

    const currentTime = new Date();
    const messageTime = new Date(time);
    const diffInMinutes = Math.floor((currentTime - messageTime) / (1000 * 60));

    if (diffInMinutes < 1) return "few moments ago";
    if (diffInMinutes === 1) return "1 minute ago";
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours === 1) return "1 hour ago";

    return `${diffInHours} hours ago`;
  };

  const toggleViewSourceDropdown = () => {
    setViewSourceDropdownOpen(!viewSourceDropdownOpen);
  };

  const downloadChatAsPDF = () => {
    if (hiddenChatRef.current) {
      // Increase the scale to improve clarity
      html2canvas(hiddenChatRef.current, { scale: 3 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Calculate dimensions based on the canvas size for better fitting
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Adjust the width and height to fill the page while keeping the aspect ratio
        const aspectRatio = canvas.width / canvas.height;
        let imgWidth, imgHeight;

        if (aspectRatio > 1) {
          // Landscape orientation
          imgWidth = pdfWidth * 1.1; // Slightly increase width for zoom
          imgHeight = imgWidth / aspectRatio;
        } else {
          // Portrait orientation
          imgHeight = pdfHeight * 1.1; // Slightly increase height for zoom
          imgWidth = imgHeight * aspectRatio;
        }

        // Position the image to start at the top and center horizontally
        const x = (pdfWidth - imgWidth) / 2; // Center horizontally
        const y = 0; // Start from the top

        pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
        pdf.save("chat.pdf");
      });
    }
  };

  const resetChat = () => {
    resetChatMessages();
    askKarmaReset(); // Reset the context to signal a new chat
  };

  const handleRemoveFile = async (index) => {
    // Get the document ID of the file being removed
    const documentIdToRemove = uploadedFiles[index].documentId; // Ensure `documentId` exists in the uploadedFiles array

    // Create a copy of uploaded files without the removed file
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);

    // Update the state to reflect the removed file
    setUploadedFiles(updatedFiles);

    try {
      // Call the backend API to clear cache for the specific document
      await axios.delete(
        `${process.env.REACT_APP_PROD_AI_URL}/chat/clear_cache`,
        {
          data: { document_ids: [documentIdToRemove] }, // Pass the document ID in an array as the backend expects a list
        }
      );
      console.log(`Cache cleared for document ID: ${documentIdToRemove}`);
      toast.success(`Document removed and cache cleared!`, {
        position: "bottom-center",
      });

      // If all documents are removed, clear the uploadedFileText state
      if (updatedFiles.length === 0) {
        setUploadedFileText("");
      }
    } catch (error) {
      console.error("Error clearing cache for the document:", error);
      toast.error("Failed to remove the document. Please try again.", {
        position: "bottom-center",
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior of Enter key
      sendChatMessage();
      if (textareaRef.current) {
        textareaRef.current.style.height = "20px"; // Reset height to original
      }
    }
  };

  const toggleAttachDropdown = (e) => {
    e.stopPropagation(); // Prevent propagation to parent elements
    setAttachDropdownOpen(!attachDropdownOpen);
  };

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      // Create FormData and append all files under the "files" key
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      try {
        // Upload all documents to the backend
        const response = await axios.post(
          `${process.env.REACT_APP_PROD_AI_URL}/chat/upload_doc`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        // Extract document information from the response
        const { documents } = response.data;

        // Validate response and map files with document IDs
        if (documents && Array.isArray(documents)) {
          const updatedFiles = documents.map((doc, index) => ({
            name: files[index].name,
            documentId: doc.document_id, // Include document ID
          }));

          // Update state with uploaded files containing document IDs
          setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

          // Combine all extracted content for chat processing
          const extractedContents = documents
            .map((doc) => doc.content)
            .join("\n");
          setUploadedFileText((prevText) =>
            prevText ? `${prevText}\n${extractedContents}` : extractedContents
          );

          console.log("Extracted Contents:", extractedContents);
        }

        // Show success toast
        toast.success("Documents uploaded and processed successfully!", {
          position: "bottom-center",
        });
      } catch (error) {
        console.error("Error uploading documents:", error);
        toast.error("Failed to upload the files. Please try again.", {
          position: "bottom-center",
        });
      }
    }
  };
  const handleCancel = async () => {
    if (uploadedFiles.length > 0) {
      try {
        for (const file of uploadedFiles) {
          // Generate document ID based on file content (ensure consistency with backend logic)
          const fileContent = await file.arrayBuffer();
          const documentId = await crypto.subtle
            .digest("SHA-256", fileContent)
            .then((hash) =>
              Array.from(new Uint8Array(hash))
                .map((b) => b.toString(16).padStart(2, "0"))
                .join("")
            );

          // Call the backend API to clear the cache for each document
          await axios.delete(
            `${process.env.REACT_APP_PROD_AI_URL}/chat/clear_cache/${documentId}`
          );
          console.log(`Cache cleared for document ID: ${documentId}`);
        }

        // Clear all state related to uploaded files and content
        setUploadedFileText(""); // Clear extracted text
        setDocumentId(null); // Reset document ID state
        setUploadedFiles([]); // Clear uploaded files list

        // Show a success message
        toast.success(
          "All uploaded documents have been cleared successfully!",
          {
            position: "bottom-center",
            autoClose: 3000,
          }
        );
      } catch (error) {
        console.error("Error clearing cache for one or more documents:", error);
        toast.error(
          "Failed to clear cache for one or more documents. Please try again.",
          { position: "bottom-center" }
        );
      }
    } else {
      console.log("No files to cancel.");
    }
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const sendCardPrompt = (prompt) => {
    setInputValue(prompt);
    sendChatMessage();
  };

  // Modify sendChatMessage function to handle disabling
  const sendChatMessage = async (documentContent = null) => {
    if (!inputValue.trim()) return; // Prevent sending empty or whitespace-only messages

    const storedUserId = localStorage.getItem("userId");
    const storedToken = localStorage.getItem("token");
    let storedChatId = localStorage.getItem("mainSection_chat_id");

    if (!storedToken || !storedUserId) {
      console.error("Error: Token or user_id is missing.");
      return;
    }

    const newMessage = {
      sender: "user",
      content: inputValue.trim(),
      file: uploadedFiles,
    };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
    setShowActionButtons(false);
    setInputValue(""); // Clear input after sending

    if (textareaRef.current) {
      textareaRef.current.style.height = "20px";
    }

    try {
      setIsLoading(true);

      // Check and log uploadedFileText to ensure it has the combined document content
      console.log("Combined Uploaded File Text:", uploadedFileText);

      const payload = {
        token: storedToken,
        user_id: storedUserId,
        query: inputValue.trim(),
        chat_id: storedChatId || "",
        document_content: documentContent || uploadedFileText || "", // Use provided content or default to `uploadedFileText`
      };

      const response = await axios.post(
        `${process.env.REACT_APP_PROD_AI_URL}/chat/process_chat`,
        payload
      );

      const aiResponseContent = response.data.content;
      const aiResponse = { sender: "assistant", content: aiResponseContent };
      setChatMessages((prevMessages) => [...prevMessages, aiResponse]);

      if (!storedChatId && response.data.chat_id) {
        storedChatId = response.data.chat_id;
        localStorage.setItem("mainSection_chat_id", storedChatId);
      }

      if (response.data.sources) {
        setSourceDocuments((prevSources) => [
          ...response.data.sources.filter(
            (source) => !prevSources.includes(source)
          ),
          ...prevSources,
        ]);
      }

      if (!isChatNameEdited && response.data.chat_name) {
        setChatName(response.data.chat_name);
        setEditedChatName(response.data.chat_name);
      }

      if (response.data.last_message_time) {
        const newLastMessageTime = new Date(
          response.data.last_message_time
        ).toISOString();
        setLastMessageTime(newLastMessageTime);
        localStorage.setItem("mainSection_lastMessageTime", newLastMessageTime);
      }
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response?.data || error.message
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section className="main-section">
      <ToastContainer />
      {/* Header Section */}
      <section className="header-box">
        <div className="header-content">
          <div className="header-left">
            <button className="recentchat-mobile-hamburger" onClick={toggleMobileSidebar}>
              <img src={hamburgerIcon} alt="Hamburger Icon" />
            </button>

            {isEditingChatName ? (
              <div className="chat-name-edit">
                <input
                  type="text"
                  value={editedChatName}
                  onChange={handleChatNameChange}
                  onBlur={handleSaveChatName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSaveChatName();
                    }
                  }}
                  className="chat-name-input"
                  style={{ width: "100%" }}
                  autoFocus
                />
              </div>
            ) : (
              <h1 className="header-title" onClick={handleEditChatName}>
                {chatName}{" "}
                <img src={editIcon} alt="edit icon" className="edit-icon" />
              </h1>
            )}
          </div>

          <div className="header-right">
            <img
              src={clockIcon}
              alt="view source icon"
              className="view-source-icon"
              style={{ width: "2%" }}
            />
            <span className="view-source">
              {lastMessageTime && !isNaN(new Date(lastMessageTime).getTime())
                ? `Last conversation: ${formatLastMessageTime(lastMessageTime)}`
                : "No conversations yet"}
            </span>

            <img
              ref={(el) => (dropdownRefs.current[0] = el)}
              src={threeDots}
              alt="three dots icon"
              className="view-source-icon"
              onClick={toggleViewSourceDropdown}
            />
          </div>

          {/* View Source Dropdown */}
          {viewSourceDropdownOpen && (
            <div className="view-source-dropdown">
              <div className="view-source-item" onClick={downloadChatAsPDF}>
                Download Chat
              </div>
              <div className="view-source-item">Share Link</div>
              <div className="view-source-item">Disclaimer</div>
            </div>
          )}
        </div>
      </section>

      {/* Modal for Reset Confirmation */}
      {isModalOpen && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal">
            <p>Are you sure you want to reset the chat window?</p>
            <div className="confirmation-modal-buttons">
              <button onClick={resetChat} className="confirm-button">
                Confirm
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main Content Area and Right Panel Area */}
      <section
        className={`content-wrapper ${isPanelOpen ? "" : "right-panel-collapsed"
          }`}
      >
        <section className="content-wrapper">
          <section className="content-area">
            <section className="content">
              {showActionButtons && (
                <>
                  <h2>Ask Karma</h2>
                  <p className="description">
                    Get legal help, make notes, and more with Karma AI
                  </p>
                  <div className="action-buttons">
                    <button
                      className="action-btn"
                      onClick={() =>
                        sendCardPrompt("Tell me about BNS and IPC?")
                      }
                    >
                      <img
                        src={categoryIcon}
                        alt="category icon"
                        className="actionbtn-icon"
                      />
                      Tell me about BNS and IPC?
                    </button>
                    <button
                      className="action-btn"
                      onClick={() =>
                        sendCardPrompt("Help me in replying to legal notice.")
                      }
                    >
                      <img
                        src={docsIcon}
                        alt="document icon"
                        className="actionbtn-icon"
                      />
                      Help me in replying to legal notice.
                    </button>
                    <button
                      className="action-btn"
                      onClick={() => sendCardPrompt("Proofread my case file.")}
                    >
                      <img
                        src={timerIcon}
                        alt="timer icon"
                        className="actionbtn-icon"
                      />
                      Proofread my case file.
                    </button>
                  </div>
                </>
              )}

              <div className="chat-section-wrapper">
                {/* Visible Chat Display */}
                <div className="chat-display" ref={chatDisplayRef}>
                  {chatMessages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.sender}`}>
                      {msg.sender === "assistant" && (
                        <img
                          src={aiAvatar}
                          alt="AI avatar"
                          className="chat-avatar"
                        />
                      )}
                      <div className={`message-bubble ${msg.sender}`}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {msg.content}
                        </ReactMarkdown>
                      </div>
                    </div>
                  ))}
                  {isLoading && (
                    <div className="loading-indicator">
                      <img
                        src={infiniteLoader}
                        alt="Typing indicator"
                        className="typing-indicator-gif"
                      />
                    </div>
                  )}
                </div>

                {/* Chat Input */}
                <section className="chat-section">
                  <div className="chatbox-wrapper">
                    {/* Uploaded Files Section - Place above the chatbox */}
                    {uploadedFiles.length > 0 && (
                      <div className="uploaded-files-container">
                        {/* Display each uploaded file */}
                        {uploadedFiles.map((file, index) => (
                          <div key={index} className="uploaded-file">
                            <span>{file.name}</span>
                            <button
                              onClick={() => handleRemoveFile(index)} // Ensure this points to the correct function
                              className="remove-button"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="chatbox-container">
                      <div className="chatbox-input-wrapper">
                        <textarea
                          ref={textareaRef}
                          className="chatbox-input"
                          placeholder="Type something"
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          rows={1}
                          style={{ maxHeight: "80px", minHeight: "20px" }}
                          disabled={isLoading}
                        />
                        <div className="chatbox-buttons">
                          <div className="attach-dropdown-wrapper">
                            <button
                              className="chatbox-icon"
                              ref={(el) => (dropdownRefs.current[2] = el)}
                              onClick={toggleAttachDropdown}
                            >
                              <img src={attachIcon} alt="attach icon" />
                            </button>

                            {attachDropdownOpen && (
                              <div className="attach-dropdown">
                                <div
                                  className="attach-dropdown-item"
                                  onClick={triggerFileUpload}
                                >
                                  Upload Document
                                </div>
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={handleFileUpload}
                                  multiple // Allow multiple files
                                />
                              </div>
                            )}
                          </div>

                          <button
                            className="chatbox-send"
                            onClick={sendChatMessage}
                            disabled={isLoading || !inputValue.trim()}
                          >
                            <span className="submit-text">Submit</span>
                            <img
                              src={sendchatIcon}
                              alt="send icon"
                              className="send-icon"
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="chatbox-refresh"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <img
                          src={refreshIcon}
                          alt="refresh icon"
                          className="refresh-icon"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="chat-caption">
                    Karma AI chats should not be taken into courts as
                    references. It only provides you with intelligence.
                  </div>
                </section>
              </div>

              {/* Hidden Chat Container for PDF Generation */}
              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px",
                }}
                ref={hiddenChatRef}
              >
                <div className="chat-display">
                  {chatMessages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.sender}`}>
                      {msg.sender === "assistant" && (
                        <img
                          src={aiAvatar}
                          alt="AI avatar"
                          className="chat-avatar"
                        />
                      )}
                      <div className={`message-bubble ${msg.sender}`}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {msg.content}
                        </ReactMarkdown>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </section>

          {/* Right Panel Section */}
          <RightPanel
            isPanelOpen={isPanelOpen}
            togglePanel={togglePanel}
            sourceDocuments={sourceDocuments} // Pass source documents to RightPanel
          />
        </section>
      </section>
    </section>
  );
};

export default MainSection;
