import React, { useState, useEffect, useRef } from 'react';
import "../css/CaseList.css";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import hamburgerIcon from "../icons/hamburger-icon.svg";
import rightIcon from "../icons/right-icon.svg";
import searchIcon from "../icons/search-icon.svg";
import backIcon from "../icons/Back-button-icon.svg";

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function ActsList({ toggleMobileSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();

    const [acts, setActs] = useState(location.state?.acts || []);
    const [searchQuery, setSearchQuery] = useState('');
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false); // Loading state
    const [isModalLoading, setIsModalLoading] = useState(false); // Loading state for the modal
    const observer = useRef();

    // Use folder name from location state or default to 'Acts'
    const folderName = location.state?.folderName || 'Acts';

    useEffect(() => {
        // If acts are not passed through location, fetch them
        if (acts.length === 0) {
            fetchActs();
        }
    }, []);

    useEffect(() => {
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                fetchActs();
            }
        });

        const sentinel = document.querySelector('#sentinel');
        if (sentinel) observer.current.observe(sentinel);

        return () => {
            if (sentinel) observer.current.unobserve(sentinel);
        };
    }, [hasMore, loading]);

    const fetchActs = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/fetchActs`, {
                params: {
                    folder: folderName,
                    cursor: cursor,
                    limit: 10,
                },
            });

            if (response.data && Array.isArray(response.data.acts)) {
                if (response.data.acts.length > 0) {
                    setActs((prevActs) => [...prevActs, ...response.data.acts]);
                    setCursor(response.data.cursor);
                    setHasMore(response.data.cursor !== null);
                } else {
                    setHasMore(false);
                }
            } else {
                console.error('Unexpected response structure:', response.data);
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching acts:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleThreadClick = async (url, actName) => {
        setIsModalLoading(true); // Show loading modal
        try {
            const pdfResponse = await axios.get(url, { responseType: 'blob' });
            if (!pdfResponse || !pdfResponse.data) {
                console.error('Failed to download the PDF from the provided URL');
                return;
            }

            const formData = new FormData();
            formData.append('file', new File([pdfResponse.data], `${actName}.pdf`, { type: 'application/pdf' }));

            const uploadResponse = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/upload_act_pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status === 200) {
                const { act_document_id } = uploadResponse.data;
                localStorage.setItem('act_document_id', act_document_id);
                console.log('File uploaded and processing triggered:', uploadResponse.data.message);

                navigate('/addacts', {
                    state: { actName, pdfUrl: url, documentId: act_document_id }
                });
            } else {
                console.error('Error uploading the file:', uploadResponse.data.detail);
            }
        } catch (error) {
            console.error('Error processing PDF:', error);
        } finally {
            setIsModalLoading(false); // Hide loading modal
        }
    };

    const removePdfExtension = (title) => {
        return title.replace(/\.pdf$/i, '');
    };

    const filteredActs = acts.filter(act =>
        removePdfExtension(act.actName).toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="caselist">
            <LoadingModal isLoading={isModalLoading} /> {/* Show loading modal when an act is clicked */}
            
            <div className="caselist-header">
                {/* <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button> */}
                <button className="landmark-back-button" onClick={() => navigate(-1)}>
                    <span><img src={backIcon} alt="close icon" /></span>
                </button>
                <h3>{folderName}</h3>
            </div>


            <div className="section-divider"></div>
            

            <div className="casefinder-search-bar">
                <div className="casefinder-search-wrapper">
                    <img className="casefinder-search-icon" src={searchIcon} alt="" />
                    <input
                        type="text"
                        placeholder="Search Court Judgment"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            
            <div className="caselist-content" >
                <div className="caselist-thread-section">
                    {filteredActs.map((act, index) => (
                        <div
                            key={index}
                            className="caselist-thread-item"
                            onClick={() => handleThreadClick(act.url, removePdfExtension(act.actName))}
                        >
                            <div className="caselist-thread-title">{removePdfExtension(act.actName)}</div>
                            <div className="caselist-thread-body">Click to view the Original Act</div>
                            <div className="caselist-thread-meta">
                                <span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0-8 4-15.5 10.7-20l96-64c11-7.4 25.9-4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                    </svg>
                                    {act.date}
                                </span>
                            </div>
                        </div>
                    ))}

                    {filteredActs.length === 0 && !loading && (
                        <div className="no-results">
                            No results found
                        </div>
                    )}

                    {loading && <div>Loading...</div>}
                    <div id="sentinel" style={{ height: '1px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default ActsList;
