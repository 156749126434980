import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/VerifyEmail.css"; // Add custom CSS for styling

const VerifyEmail = () => {
  const [status, setStatus] = useState("Verifying your email...");
  const [success, setSuccess] = useState(false); // Track verification status
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/verify-email`, { token });
        setStatus(response.data.message);
        setSuccess(true);
      } catch (error) {
        setStatus(error.response?.data?.message || "Verification failed.");
        setSuccess(false);
      }
    };

    if (token) verifyEmail();

    // Redirect to login page after 5 seconds
    const redirectTimer = setTimeout(() => {
      navigate("/login");
    }, 5000);

    return () => clearTimeout(redirectTimer); // Cleanup timeout on component unmount
  }, [token, navigate]);

  return (
    <div className={`verify-email-container ${success ? "success" : "error"}`}>
      <h1>Email Verification</h1>
      <p className="status-message">{status}</p>
      <p className="redirect-message">You will be redirected to the login page shortly...</p>
    </div>
  );
};

export default VerifyEmail;
