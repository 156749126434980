import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import "../css/CaseList.css";
import { FaSearch } from "react-icons/fa";
import hamburgerIcon from "../icons/hamburger-icon.svg";
import caseFinderHeaderIcon from "../icons/casefinder-header-icon.svg";
import backIcon from "../icons/right-icon.svg";
import searchIcon from "../icons/search-icon.svg";

function CaseList({ toggleMobileSidebar }) {
    const [cases, setCases] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFilter2, setSelectedFilter2] = useState('All'); // Case Type Filter
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const observer = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    // Extract year from query params
    const queryParams = new URLSearchParams(location.search);
    const selectedYear = queryParams.get("year");

    useEffect(() => {
        fetchCases(true); // Reset cases when filters or year changes
    }, [selectedYear, selectedFilter2, searchQuery]);

    useEffect(() => {
        if (!hasMore || loading) return;

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                fetchCases();
            }
        });

        const sentinel = document.querySelector('#sentinel');
        if (sentinel) observer.current.observe(sentinel);

        return () => {
            if (sentinel) observer.current.unobserve(sentinel);
        };
    }, [hasMore, loading]);

    const fetchCases = async (reset = false) => {
        if (reset) {
            setCases([]);
            setCursor(null);
            setHasMore(true);
        }

        if (!hasMore) return;

        setLoading(true);

        try {
            console.log('Fetching cases with:', {
                cursor,
                year: selectedYear,
                caseType: selectedFilter2 !== 'All' ? selectedFilter2 : null,
                searchQuery: searchQuery.trim() || null,
            });

            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/fetchCases`, {
                params: {
                    cursor,
                    limit: 10,
                    year: selectedYear,
                    caseType: selectedFilter2 !== 'All' ? selectedFilter2 : null,
                    searchQuery: searchQuery.trim() || null,
                },
            });

            console.log('Response data:', response.data);

            if (response.data.cases.length > 0) {
                setCases((prevCases) => [...prevCases, ...response.data.cases]);
                setCursor(response.data.cursor);
                setHasMore(response.data.cursor !== null);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching cases:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleThreadClick = (caseItem) => {
        const { url, caseName, case_type, date, judges, citation } = caseItem;
        uploadAndProcessPdf(url, caseName, { case_type, date, judges, citation });
    };

    const uploadAndProcessPdf = async (url, caseName, additionalCaseData) => {
        try {
            const pdfResponse = await axios.get(url, { responseType: 'blob' });
            if (!pdfResponse || !pdfResponse.data) {
                console.error('Failed to download the PDF from the provided URL');
                return;
            }

            const formData = new FormData();
            formData.append('file', new File([pdfResponse.data], `${caseName}.pdf`, { type: 'application/pdf' }));

            const uploadResponse = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/upload_case_pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status === 200) {
                const document_id = uploadResponse.data.case_document_id;
                localStorage.setItem('case_document_id', document_id);
                navigate('/addcase', {
                    state: {
                        pdfUrl: url,
                        caseName,
                        document_id,
                        ...additionalCaseData
                    }
                });
            } else {
                console.error('Error uploading the file:', uploadResponse.data.detail);
            }
        } catch (error) {
            console.error('Error processing PDF:', error);
        }
    };

    const removePdfExtension = (title) => {
        return title.replace(/\.pdf$/i, '');
    };



    return (
        <div className="caselist">
            <div className="caselist-header">
                <h3>Cases from {selectedYear}</h3>
                <button
                    className="landmark-back-button"
                    onClick={() => navigate(-1)}
                    style={{ transform: 'rotate(180deg)' }}
                >
                    <span><img src={backIcon} alt="close icon" /></span>
                </button>
            </div>
            <div className='section-divider'></div>

            <div className='search-and-filters'>
                <div className="casefinder-search-bar">
                    <div className="casefinder-search-wrapper" style={{ "marginTop": "10px" }}>
                        <img className="casefinder-search-icon" src={searchIcon} alt="" />
                        <input
                            type="text"
                            placeholder="Search Court Judgment"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
                <div className="caselist-filters">
                    <select
                        value={selectedFilter2}
                        onChange={(e) => setSelectedFilter2(e.target.value)}
                        disabled={loading}
                    >
                        {/* Add all case types here */}
                        <option value="All">Case Type</option>
                        <option value="civil appeal">Civil Appeal</option>
                        <option value="contempt petition">Contempt Petition</option>
                        <option value="criminal appeal">Criminal Appeal</option>
                        <option value="criminal miscellaneous petition">Criminal Miscellaneous Petition</option>
                        <option value="election petition">Election Petition</option>
                        <option value="excise reference no">Excise Reference No</option>
                        <option value="habeas corpus petition">Habeas Corpus Petition</option>
                        <option value="miscellaneous application">Miscellaneous Application</option>
                        <option value="original suit">Original Suit</option>
                        <option value="ref">Ref</option>
                        <option value="review petition">Review Petition</option>
                        <option value="select case type">Select Case Type</option>
                        <option value="special leave petition">Special Leave Petition</option>
                        <option value="special leave to petition">Special Leave To Petition</option>
                        <option value="special reference case">Special Reference Case</option>
                        <option value="suo moto contempt petition">Suo Moto Contempt Petition</option>
                        <option value="transfer petition">Transfer Petition</option>
                        <option value="transferred case">Transferred Case</option>
                        <option value="writ petition">Writ Petition</option>
                        <option value="writ to petition">Writ To Petition</option>
                    </select>
                </div>
            </div>
            <div className="caselist-content">
                <div className="caselist-thread-section">
                    {cases.map((caseItem, index) => (
                        <div key={index} className="caselist-thread-item" onClick={() => handleThreadClick(caseItem)}>
                            <div className="caselist-thread-title">{removePdfExtension(caseItem.caseName)}</div>
                            <div className="caselist-thread-body">{`Case Type: ${caseItem.case_type || 'N/A'}`}</div>
                            <div className="caselist-thread-meta">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ "width": "1.5%" }}>
                                    <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                </svg>
                                <span className='date-meta'>{caseItem.date}</span>
                            </div>
                        </div>
                    ))}
                    {cases.length === 0 && !loading && <div className="no-results">No results found</div>}
                    {loading && <div>Loading...</div>}
                    <div id="sentinel" style={{ height: '1px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default CaseList;