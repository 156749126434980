import React, { useState, useEffect, useContext } from 'react';
import '../css/RecentChats.css';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChatContext } from './ChatContext';
import hamburgerIcon from "../icons/hamburger-icon.svg";
import searchIcon from "../icons/search-icon.svg";

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function ArchiveChat({ toggleMobileSidebar }) {
    const [archivedChats, setArchivedChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state for the modal
    const navigate = useNavigate();
    const { setChatData } = useContext(ChatContext);

    const fetchArchivedChats = async () => {
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                console.error('User ID not found in localStorage');
                return;
            }

            const response = await axios.get(`${process.env.REACT_APP_PROD_AI_URL}/chat/fetch_archived_chats`, {
                params: { user_id: userId }
            });

            setArchivedChats(response.data.chats || []);
        } catch (error) {
            console.error('Error fetching archived chats:', error);
        } finally {
            setLoading(false); // Hide loading modal once data is fetched
        }
    };

    useEffect(() => {
        fetchArchivedChats();
    }, []);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleChatClick = (chat) => {
        setSelectedChat(chat);
        setIsModalVisible(true);
    };

    const unarchiveChat = async () => {
        if (!selectedChat) return;

        try {
            await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/unarchive_chat`, null, {
                params: { session_id: selectedChat.session_id }
            });

            setArchivedChats(prev => prev.filter(chat => chat.chat_id !== selectedChat.chat_id));
            setIsModalVisible(false);

            navigate('/recentchats');
            console.log(`Unarchived chat session with ID: ${selectedChat.chat_id}`);
        } catch (error) {
            console.error('Error unarchiving chat:', error);
        }
    };

    return (
        <div className="recentchat">
            <LoadingModal isLoading={loading} /> {/* Show loading modal when loading */}

            <div className="recentchat-header">
                <button className="back-button" onClick={handleBackClick}>Back</button>
                <button className="recentchat-mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <h3>{archivedChats.length} Archived Chats</h3>
            </div>

            <div className="section-divider"></div>

            <div className="casefinder-search-bar">
                <div className="casefinder-search-wrapper">
                    <img className="casefinder-search-icon" src={searchIcon} alt="" />

                    <input type="text" placeholder="Search archived chats" />
                </div>
            </div>
            <div id="divider"></div>

            <div className="recentchat-content" >
                <div className="recentchat-threads-section">
                    {archivedChats.map((session, index) => (
                        <div
                            key={index}
                            className="recentchat-thread-item"
                            onClick={() => handleChatClick(session)}
                        >
                            <div className="recentchat-thread-title">
                                {session.chat_name}
                            </div>
                            <div className="recentchat-thread-meta">
                                <div className="thread-meta-left">
                                    <span>{session.chat_history.length} messages</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Unarchive confirmation modal */}
            {isModalVisible && (
                <div className="archivemodal-overlay">
                    <div className="archivemodal">
                        <h2>Unarchive Chat</h2>
                        <p>Are you sure you want to unarchive this chat?</p>
                        <div className="archivemodal-buttons">
                            <button onClick={unarchiveChat} className="archiveconfirm-button">Yes</button>
                            <button onClick={() => setIsModalVisible(false)} className="archivecancel-button">No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ArchiveChat;
