import React, { useState } from 'react';
import '../css/KarmaDocs.css';
import { FaSearch } from 'react-icons/fa';
import ThreadOptionsDropdown from './ThreadDropdown';
import { useNavigate } from 'react-router-dom';
import caseFinderHeaderIcon from "../icons/casefinder-header-icon.svg"
import docIcon from "../icons/document-icon.svg"
import clockIcon from "../icons/clock-icon.svg"
import archiveIcon from "../icons/archive-chat-icon.svg"
import threedotIcon from "../icons/three-dots-icon.svg"
import thrashIcon from "../icons/thrash-icon.svg"
import hamburgerIcon from "../icons/hamburger-icon.svg";
import searchIcon from "../icons/search-icon.svg";



function KarmaDocs({ toggleMobileSidebar }) {

    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const navigate = useNavigate();

    const documents = ["Document 1", "Document 2", "Document 3"];

    // Toggle dropdowns dynamically
    const toggleDropdown1 = () => {
        setDropdownOpen1(prev => !prev);

    };

    const goToArchive = () => {
        navigate('/archive')
    }

    return (
        <div className="karmadocs">
            <div className="karmadocs-header" onClick={goToArchive}>
                {/* <img className='karmadocs-header-icon' src={caseFinderHeaderIcon} alt="recent chat header" /> */}
                
                <button className="recentchat-mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                
                <h3>Karma Documents</h3>
            </div>

            <div className="section-divider"></div>

            {/* Moved the search bar below the title */}
            <div className="casefinder-search-bar">
                <div className="casefinder-search-wrapper">
                    <img className="casefinder-search-icon" src={searchIcon} alt="" />

                    <input type="text" placeholder="Search your Documents" />
                </div>
            </div>
            <div id="divider"></div>

            <div className="karmadocs-content" >
                <div className="karmadocs-threads-section">
                    <div className="karmadocs-threads-header">
                        {/* <h2>38 Recent Chats</h2> */}
                        {/* <div className="recentchat-threads-actions">
                            <button className="recentchat-dropdown-button" onClick={toggleDropdown1}>
                                <img src={threedotIcon} alt="" />
                            </button>

                            <div className={`recentchat-dropdown-content ${dropdownOpen1 ? 'show' : ''}`}>
                                <div className="dropdown-option">
                                    <img src={thrashIcon} alt="Trash Icon" className="thrash-icon" />
                                    <span className="delete-all-text">Delete all Chats</span>
                                </div>
                            </div>
                        </div> */}


                    </div>
                    {/* <div className="karmadocs-thread-item">
                        <div className="karmadocs-thread-title">
                        Your Document (Delete, Open, Download Chat, Add to My Case)
                        </div>
                       
                        <div className="recentchat-thread-meta">
                            <div className="thread-meta-left">
                                
                                <span>12:15 Today</span>
                            </div>

                            <div className="thread-meta-right">
                                <ThreadOptionsDropdown />
                            </div>
                        </div>
                    </div> */}


{/* 
                    <div className="recentchat-thread-item">
                        <div className="recentchat-thread-title">
                            Your Document
                        </div>
                      
                        <div className="recentchat-thread-meta">
                            <div className="thread-meta-left">
                               
                                <span>12:15 Today</span>
                            </div>

                            <div className="thread-meta-right">
                                <ThreadOptionsDropdown />
                            </div>
                        </div>
                    </div>


                    <div className="recentchat-thread-item">
                        <div className="recentchat-thread-title">
                        Your Document
                        </div>
                        
                        <div className="recentchat-thread-meta">
                            <div className="thread-meta-left">
                               
                                <span>12:15 Today</span>
                            </div>

                            <div className="thread-meta-right">
                                <ThreadOptionsDropdown />
                            </div>
                        </div>
                    </div> */}



                    {/* <div className="recentchat-thread-item">
                        <div className="recentchat-thread-title">
                        Your Document
                        </div>
                        
                        <div className="recentchat-thread-meta">
                            <div className="thread-meta-left">
                                
                                <span>12:15 Today</span>
                            </div>

                            <div className="thread-meta-right">
                                <ThreadOptionsDropdown />
                            </div>
                        </div>
                    </div>



                    <div className="recentchat-thread-item">
                        <div className="recentchat-thread-title">
                        Your Document
                        </div>
                      
                        <div className="recentchat-thread-meta">
                            <div className="thread-meta-left">
                                
                                <span>12:15 Today</span>
                            </div>

                            <div className="thread-meta-right">
                                <ThreadOptionsDropdown />
                            </div>
                        </div>
                    </div>
                    <div className="recentchat-thread-item">
                        <div className="recentchat-thread-title">
                        Your Document
                        </div>
                       
                        <div className="recentchat-thread-meta">
                            <div className="thread-meta-left">
                                
                                <span>12:15 Today</span>
                            </div>

                            <div className="thread-meta-right">
                                <ThreadOptionsDropdown />
                            </div>
                        </div>
                    </div> */}

                </div>
                <div className='recentchat-right-panel'>
                    {/* <div className="recentchat-collections-section">
                        <div className='recentchat-collection-header'>
                          
                            <h2> Archive Chats</h2>

                        </div>
                        <div className='recentchat-collection-box'>
                        
                            <button onClick={() => navigate('/archive')} className="get-started-button">View</button>

                        </div>
                    </div> */}

                </div>

            </div>
        </div>
    );
}

export default KarmaDocs;