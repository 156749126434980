import React, { useState } from 'react';
import '../css/CaseFinder.css';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import clockIcon from "../icons/clock-icon.svg";
import hamburgerIcon from "../icons/hamburger-icon.svg";
import axios from "axios";
import searchIcon from "../icons/search-icon.svg";

// Modal Component for Folder Selection
const Modal = ({ isOpen, onClose, onSelectFolder }) => {
    if (!isOpen) return null;

    const folders = [
        { name: "Andaman and Nicobar", value: "Andaman and Nicobar Islands" },
        { name: "Bihar", value: "Bihar" },
        { name: "Andhra Pradesh", value: "Andhra Pradesh" },
        { name: "Arunachal Pradesh", value: "Arunachal Pradesh" },
        { name: "Assam", value: "Assam" },
        { name: "Chattisgarh", value: "Chattisgarh" },
        { name: "Dadra And Nagar Haveli And Diu", value: "Dadra And Nagar Haveli And Diu" },
        { name: "Delhi", value: "Delhi" },
        { name: "Gujarat", value: "Gujarat" },
        { name: "Haryana", value: "Haryana" },
        { name: "Himachal Pradesh", value: "Himachal Pradesh" },
        { name: "Jammu and Kashmir", value: "Jammu and Kashmir" },
        { name: "Jharkhand", value: "Jharkhand" },
        { name: "Karnataka", value: "Karnataka" },
        { name: "Kerala", value: "Kerala" },
        { name: "Ladakh", value: "Ladakh" },
        { name: "Lakshadweep", value: "Lakshadweep" },
        { name: "Madhya Pradesh", value: "Madhya Pradesh" },
        { name: "Maharashtra", value: "Maharashtra" },
        { name: "Manipur", value: "Manipur" },
        { name: "Mizoram", value: "Mizoram" },
        { name: "Nagaland", value: "Nagaland" },
        { name: "Odisha", value: "Odisha" },
        { name: "Pondicherry", value: "Pondicherry" },
        { name: "Punjab", value: "Punjab" },
        { name: "Rajasthan", value: "Rajasthan" },
        { name: "Sikkim", value: "Sikkim" },
        { name: "Tamil Nadu", value: "Tamil Nadu" },
        { name: "Telangana", value: "Telangana" },
        { name: "Tripura", value: "Tripura" },
        { name: "Uttar Pradesh", value: "Uttar Pradesh" },
        { name: "Uttarakhand", value: "Uttarakhand" },
        { name: "West Bengal", value: "West Bengal" }
    ];

    return (
        <div className="actmodal-overlay" onClick={onClose}>
            <div className="actmodal-content" onClick={(e) => e.stopPropagation()}>
                <div className="actfolder-options">
                    {folders.map((folder) => (
                        <div
                            key={folder.value}
                            className="actfolder-option"
                            onClick={() => onSelectFolder(folder.value)}
                        >
                            {folder.name}
                        </div>
                    ))}
                </div>
                <button onClick={onClose} className="actclose-button">Close</button>
            </div>
        </div>
    );
};

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

const BillsModal = ({ isOpen, onClose, onSelectOption }) => {
    if (!isOpen) return null;

    const options = [
        { name: "Union", value: "Union" },
        { name: "State", value: "State" }
    ];

    return (
        <div className="actmodal-overlay" onClick={onClose}>
            <div className="actmodal-content" onClick={(e) => e.stopPropagation()}>
                <div className="actfolder-options">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="actfolder-option"
                            onClick={() => onSelectOption(option.value)}
                        >
                            {option.name}
                        </div>
                    ))}
                </div>
                <button onClick={onClose} className="actclose-button">Close</button>
            </div>
        </div>
    );
};

function ActsFinder({ toggleMobileSidebar }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isBillsModalOpen, setIsBillsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state for the modal
    const navigate = useNavigate();

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const openBillsModal = () => {
        setIsBillsModalOpen(true);
    };

    const closeBillsModal = () => {
        setIsBillsModalOpen(false);
    };

    const handleFolderSelect = async (folder) => {
        closeModal();
        setIsLoading(true); // Show loading modal
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/fetchActs?folder=${folder}`);
            const data = response.data;
            navigate('/actslist', { state: { acts: data.acts, folderName: folder } }); // Pass folder name here
        } catch (error) {
            console.error("Error fetching acts:", error);
        } finally {
            setIsLoading(false); // Hide loading modal
        }
    };


    const handleCentralActsClick = async () => {
        setIsLoading(true); // Show loading modal
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/fetchActs?folder=Central Acts`);
            const data = response.data;
            navigate('/actslist', { state: { acts: data.acts, folderName: "Central Acts" } }); // Pass folder name here
        } catch (error) {
            console.error("Error fetching central acts:", error);
        } finally {
            setIsLoading(false); // Hide loading modal
        }
    };

    const goToStateSelectionPage = () => {
        navigate('/selectState');
    };

    const handleBillsOptionSelect = async (option) => {
        closeBillsModal();
        setIsLoading(true); // Show loading modal

        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/bills`, {
                params: {
                    folder: option,    // Pass the selected option as folder (e.g., "Union Bills" or "State Bills")
                    cursor: null,      // Initial load, no cursor
                    limit: 10          // Example limit, you can set this based on your requirements
                }
            });

            const data = response.data;

            // Process the response to get bill details
            const bills = data.bills.map((bill) => ({
                name: bill.billName,
                url: bill.url
            }));

            // Navigate to the acts list page with the fetched bills data
            navigate('/billslist', { state: { acts: bills, folderName: `Union Bills` } });
        } catch (error) {
            console.error("Error fetching bills:", error);
        } finally {
            setIsLoading(false); // Hide loading modal
        }
    };

    const handleDepartmentsClick = async () => {
        setIsLoading(true); // Show loading modal
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/departments`);
            const data = response.data;
            navigate('/departments', { state: { departments: data.departments } });
        } catch (error) {
            console.error("Error fetching departments:", error);
        } finally {
            setIsLoading(false); // Hide loading modal
        }
    };

    const handleMinistriesClick = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/ministries`);
            const data = response.data;
            navigate('/ministrylist', { state: { ministries: data.ministries } });
        } catch (error) {
            console.error("Error fetching ministries:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleConstitutionClick = async () => {
        setIsLoading(true); // Show loading modal
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/constitution-amendments/files`);
            const data = response.data;
            navigate('/constitutionlist', { state: { files: data.files } });
        } catch (error) {
            console.error("Error fetching constitution amendments:", error);
        } finally {
            setIsLoading(false); // Hide loading modal
        }
    };
    



    return (
        <div className="library">
            <div className="library-header">
                <button className="recentchat-mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <h3>Law Library</h3>
            </div>
            <div className="section-divider"></div>
            {/* <div className="casefinder-search-bar">
                <div className="casefinder-search-wrapper">
                    <img className="casefinder-search-icon" src={searchIcon} alt="" />
                    <input type="text" placeholder="Search Court Judgment" />
                </div>
            </div> */}
            {/* <div id="divider"></div> */}

            <div className="library-content" >
                <div className="threads-section">
                    <div className="threads-header">
                        <h2></h2>
                    </div>
                    <div className="thread-item" onClick={handleConstitutionClick}>
                        <div className="thread-title">Constitution of India</div>
                        <div className="thread-body">The Republic of India.</div>
                    </div>
                    <div className="thread-item" onClick={handleCentralActsClick}>
                        <div className="thread-title">Central Acts</div>
                        <div className="thread-body">876 Acts.</div>
                    </div>
                    <div className="thread-item" onClick={goToStateSelectionPage}>
                        <div className="thread-title">State Acts</div>
                        <div className="thread-body">28 Indian States and 8 Union Territories</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item" >
                        <div className="thread-title" onClick={openBillsModal}>Bills</div>
                        <div className="thread-body">Proposed laws under consideration by the legislature.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Law Commission</div>
                        <div className="thread-body">Reports and recommendations for legal reforms.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Committee Reports</div>
                        <div className="thread-body">Insights and findings from various governmental committees</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item" onClick={handleMinistriesClick}>
                        <div className="thread-title">Ministries</div>
                        <div className="thread-body">Legal frameworks and policies from central government ministries.</div>
                    </div>

                    <div className="thread-item" onClick={handleDepartmentsClick}>
                        <div className="thread-title">Departments</div>
                        <div className="thread-body">Guidelines and regulations from governmental departments.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Schemes/Yojanas</div>
                        <div className="thread-body">Government programs for social and economic welfare.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Legal Procedures</div>
                        <div className="thread-body">Standard procedures in the Indian legal system.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Code of Conduct</div>
                        <div className="thread-body">Guidelines on ethical practices and behavior.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Statistics</div>
                        <div className="thread-body">Legal data and statistics across various sectors.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">Minimum Wage</div>
                        <div className="thread-body">Legislation on minimum wages for different occupations.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                    <div className="thread-item">
                        <div className="thread-title">International Law</div>
                        <div className="thread-body">Laws governing India’s interactions with other nations.</div>
                        <div className="thread-meta">
                            {/* <img className='thread-meta-icon' src={clockIcon} alt="clock icon" /> */}
                            {/* <span> 2 days ago</span> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for folder selection */}
            <Modal isOpen={isOpen} onClose={closeModal} onSelectFolder={handleFolderSelect} />

            <BillsModal isOpen={isBillsModalOpen} onClose={closeBillsModal} onSelectOption={handleBillsOptionSelect} />

            {/* Loading Modal */}
            <LoadingModal isLoading={isLoading} />
        </div>
    );
}

export default ActsFinder;
